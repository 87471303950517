import React from "react";
import styled from "styled-components";

const Footer = () => {
  return (
    <FooterStyled>
      <h3>Terms and Conditions</h3>
      <h3>Call us 135</h3>
      <h3>To unsubscribe dial *155#</h3>
    </FooterStyled>
  );
};

export default Footer;

const FooterStyled = styled.div`
  background-image: linear-gradient(135deg, #52e5e7 10%, #130cb7 100%);
  height: 6vh;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  h3 {
    cursor: pointer;
  }
  /* position: absolute;
  bottom: 0; */
`;
